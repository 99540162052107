/* eslint-disable radix */
import React, { useRef, useCallback, useEffect, useState } from 'react'

import { FiMail, FiCalendar, FiClock, FiSmartphone, FiUser } from 'react-icons/fi'
import {BiCake} from 'react-icons/bi'

import * as Yup from 'yup'

import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'

import { useHistory } from 'react-router-dom'

import { Container, Content, BoxValue, BtnVoltar } from './styles'

import Header from '../../components/Header'
import usePersistedState from '../../hooks/usePersistedState'
import Input from '../../components/Input'
import InputRange from '../../components/InputRange'

import wallet from '../../assets/wallet.svg'
import Button from '../../components/Button'
import InputHidden from '../../components/InputHidden'
import { formatValue } from '../../utils/formatValues'
import getValidationErrors from '../../utils/getValidationErrors'
import { ValorMascarar } from '../../utils/masks'

import { UserData, UserDetails } from '../../utils/interfaces'
import moment from 'moment'
import calculaIdade from '../../utils/calculaIdade'
import InputSelect from '../../components/InputSelect'
import { MdSecurity } from 'react-icons/md'
import validaCPF from '../../utils/validaCPF'

const PersonalInfos: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [contribValue, setContribValue] = useState(
    userDetails.contribuicaoBasica === null || userDetails.contribuicaoBasica === undefined
      ? 79
      : userDetails.contribuicaoBasica,
  )
  const [dcrContribValue, setDcrContribValue] = useState(
    contribValue && contribValue !== 79
      ? ValorMascarar(`${contribValue.toFixed(2)}`)
      : '79,00',
  )
  const [patrocinadora, setPatrocinadora] = useState({
    label:
      userData.dcrPatrocinadora === undefined ? '' : userData.dcrPatrocinadora,
    value: userData.patrocinadora === undefined ? '' : userData.patrocinadora,
  })
  const [patroc, setPatroc] = useState(userData.patrocinadora)

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(5, 'Digite o nome completo'),
          cpf: Yup.string()
              .required('CPF obrigatório')
              .test(
                '',
                'CPF inválido',
                () =>
                  validaCPF(data.cpf.replaceAll('.', '').replace('-', '')) ||
                  data.cpf === '',
              ),
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .min(14, 'Telefone inválido')
            .required('Celular obrigatório'),
          birthdate: Yup.string()
            .required('Data de nascimento obrigatória')
            .test(
              '',
              'A data de nascimento não pode ser maior que hoje.',
              () =>
                moment() >
                  moment(data.birthdate.split('/').reverse().join('-')) ||
                data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                moment(
                  data.birthdate.split('/').reverse().join('-'),
                ).isValid() || data.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                calculaIdade(data.birthdate.split('/').reverse().join('-')) <=
                  115 || data.birthdate === '',
            ),
          years: Yup.number()
            .min(1, 'Tempo de contribuição mínimo: 1 ano')
            .max(100, 'Tempo de contribuição máximo: 100 anos')
            .typeError('Digite o tempo para realizar a simulação')
            .required('Digite o tempo para realizar a simulação'),

        })

        await schema.validate(data, { abortEarly: false })

        const aa = data.birthdate
        const dia = aa.split('/')[0]
        const mes = aa.split('/')[1]
        const ano = aa.split('/')[2]
        const dataForm = `${ano}-${`0${mes}`.slice(-2)}-${`0${dia}`.slice(-2)}`

        setUserDetails({
          ...userDetails,
          contribuicaoBasica: contribValue * 1,
          years: data.years,
          age: calculaIdade(dataForm),
        })

        setUserData({
          ...userData,
          name: data.name,
          cpf: data.cpf,
          phone: data.phone,
          email: data.email,
          birthdate: dataForm,
        })
        history.push('/simulation')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [contribValue, history, setUserData, setUserDetails, userData, patrocinadora],
  )

  const handleChangePatrocinadora = useCallback(e => {
    const t = e
    setPatrocinadora(t)
  }, [])

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  const handleFocus = useCallback(() => {
    console.log(document.getElementById('contributionInput'))
  }, [])

  const mudarContrib = useCallback(
    valor => {
      const v = valor.replaceAll('.', '').replace(',', '.')
      setContribValue(parseFloat(v))
      setDcrContribValue(ValorMascarar(valor))
    },
    [setContribValue],
  )

  const validarContrib = useCallback(
    valor => {
      const v = valor.replaceAll('.', '').replace(',', '.')
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(v)) {
        setContribValue(0)
        setDcrContribValue('0,00')
      } else if (v < 79) {
        setContribValue(10)
        setDcrContribValue('79,00')
      } else {
        setContribValue(parseFloat(v))
        setDcrContribValue(ValorMascarar(valor))
      }
    },
    [setContribValue],
  )

  const [dtNasc, setDtNasc] = useState(
    userData.birthdate === undefined
      ? ''
      : userData.birthdate.split('-').reverse().join('/'),
  )

  const handleOnKeyDown = useCallback(e => {
    if (e.which === 8) {
      let val = e.target.value
      if (val.length === 3 || val.length === 6) {
        val = val.slice(0, val.length - 1)
        setDtNasc(val)
      }
    }
  }, [])
  const handleOnChange = useCallback(
    e => {
      let val = e.target.value
      if (val.length === 2) {
        val += '/'
      } else if (val.length === 5) {
        val += '/'
      } else if (val.length > 10) {
        val = dtNasc
      }
      setDtNasc(val)
    },
    [dtNasc, setDtNasc],
  )

  const changeFormContrib = useCallback(
    val => {
      const field = formRef.current?.getFieldRef('contribution')
      field.value = val
      setUserDetails(() => ({ ...userDetails, contribution: val }))
    },
    [setUserDetails, userDetails],
  )
  useEffect(() => {
    changeFormContrib(contribValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contribValue])

  return (
    <>
      <Header>
        Que ótimo, {userData.name.split(' ')[0]}!
        <br /> Queremos saber mais sobre você:
      </Header>
      <Container>
        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              name: userData.name,
              cpf: userData.cpf,
              email: userData.email,
              phone: userData.phone,
              birthdate:
                userData.birthdate === undefined
                  ? ''
                  : userData.birthdate.split('-').reverse().join('/'),
              years: userDetails.years,
              contribution: userDetails.contribuicaoBasica,
            }}
          >
          <Input
            placeholder="Nome completo"
            name="name"
            icon={FiUser}
          />
          <Input
            placeholder="CPF"
            name="cpf"
            icon={MdSecurity}
            type="tel"
            mask="cpf"
          />
            <InputHidden name="contribution" type="hidden" />
            <Input
              icon={BiCake}
              name="birthdate"
              mask="date"
              placeholder="Data de nascimento"
              //onBlur={e => handleDataNascimento(e.target.value)}
            />
            <Input
              icon={FiMail}
              name="email"
              type="email"
              readOnly
              placeholder="Seu e-mail pessoal"
            />
            <Input
              name="phone"
              mask="phone"
              prefix="+55 | "
              placeholder="Seu celular com DDD"
              icon={FiSmartphone}
            />
            <Input
              sizeBox="large"
              icon={FiClock}
              name="years"
              type="number"
              min={1}
              sufix="anos"
              placeholder="Por quantos anos deseja pagar sua previdência privada
              até começar a recebê-la?"
            />
            {/* <InputSelect
              name="patrocinadora"
              value={patrocinadora}
              options={[
                { label: 'SESI', value: 1 },
                { label: 'FIEMG', value: 2 },
                { label: 'IEL', value: 5 },
                { label: 'SENAI', value: 9 },
                { label: 'IER', value: 12 },
              ]}
              onChange={e => handleChangePatrocinadora(e)}
              placeholder="Patrocinadora"
            /> */}
          </Form>
          <BoxValue>
            <p>Qual valor mensal?</p>
            <span>Contribuição a partir de R$79,00 </span>
            {/* <InputRange
              name="contribution"
              initialValue={100.0}
              start={100.0}
              end={1000.0}
              step={10}
              prefix="R$"
              functionChange={setContribValue}
            /> */}
            <div>
              <img src={wallet} alt="wallet" />
              <div>
                <p>Quero investir:</p>
                <input
                  id="contribution"
                  value={dcrContribValue}
                  onChange={e => mudarContrib(e.target.value)}
                  type="text"
                  min="79"
                  onBlur={e => validarContrib(e.target.value)}
                />
                <small>Valor mensal recorrente</small>
              </div>
            </div>
          </BoxValue>
          <small>
            Está em dúvida? Não se preocupe, depois você poderá alterar este
            valor sempre que desejar.
          </small>
          <Button type="submit" color="green" onClick={handleClick}>
            Simular Agora
          </Button>
          <BtnVoltar type="button" onClick={() => history.goBack()}>
            &lt; Anterior
          </BtnVoltar>
        </Content>
      </Container>
    </>
  )
}

export default PersonalInfos
