import styled from 'styled-components';

export const Container = styled.div`
  background-color: #55B1FF;
  box-shadow: 0px 12px 8px rgba(85,177,255,0.4);
  border-radius: 4px;
  padding: 8px 12px;
    text-align: center;
  span{
    font-size: 14px;
    font-weight: bolder!important;
    text-align: center;
    color: #fff;
    display:block;
  }
`;
