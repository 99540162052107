import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 15px;
  justify-content: space-evenly;
  height: 100vh;
  margin:0 auto;

  max-width: 420px;

  @media screen and (min-width: 766px) {
    max-width: 550px;
  }
`;


export const DivContent = styled.main`
  display: flex;
  flex-direction: row;

  @media screen and (min-width: 766px) {
    width: 500px;
  }

`

interface ContentProps {
  isActive: boolean;
}
export const Content = styled.div<ContentProps>`
  display: flex;/*${props => props.isActive ? 'flex' : 'none'};*/
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;

  flex-direction: column;
  align-items: center;
  max-width: 315px;

  height: 410px;
  width: ${props => props.isActive ? '100%' : '0px'};


  opacity: ${props => props.isActive ? 1 : 0};

  pointer-events: ${props => props.isActive ? 'initial' : 'none'};
  img {
    margin-bottom: 35px;
  }
  strong {
    font-size: 16px;
    margin-bottom: 15px;
    text-align: center;


  }
  > span {
    font-size: 15px;
    text-align: center;
  }

  > small {
    font-size: 12px;
    text-align: center;
    margin: 20px;
  }

  > a {
    color: #218838;
  }
  > div{
    height: 40px;
    margin-top: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > small {
    font-size: 12px;
    text-align: center;
    margin: 20px;
  }

    > span{
      flex: 1;
      font-size: 14px;
      margin-right: 15px;
    }
  }


  @media screen and (min-width: 766px) {
    max-width:500px;
    height: 460px;
    padding-top:15px;

    img {
      margin-bottom: 38px;
    }

    strong {
      font-size: 18px;
    }
    > span {
      font-size: 17px;
    }
    > div {
      height: 30px;
      margin-top:22px;

      >span {
        font-size: 16px;
      }
    }
  }
`

export const Footer = styled.footer`

  margin-top: 25px;

  display:flex;
  flex-direction: column;

  align-items: center;
    height: 130px;

  @media screen and (min-width: 766px) {
    margin: 15px 0;
  }
`
export const BtnVoltar = styled.button`
  border: none;
  background-color: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid transparent;
  padding: 0px 3px 2px;
  color: ${({ theme }) => theme.colors.text};

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`
