import styled from 'styled-components';

interface BtnContatoProps {
  isActive: boolean;
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 500px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 420px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;

  transition: opacity 0.3s ease-in-out;

  h3 {
    width: 90%;
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 10px;
    text-align: center;

    strong {
    }
  }

  h3#indication {
    width: 100%;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 550;
    margin-bottom: 10px;
    text-align: left;
    padding-left: 5px;
  }

  form {
    width: 100%;
  }
`

// export const RadioButton = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   position:relative;

//   width: 100%;
//   padding: 10px 12px 5px;
//   transition: .2s;

//   > label {
//     transform: translateY(-12px);
//     font-size: 14px;
//   }

//   > div {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-evenly;
//     width: 100%;
//     margin: 0 auto;
//   }

//   > div.title {
//     transform: translateY(-12px);
//     justify-content: left;

//     small {
//       font-size: 14px;
//     }

//     svg {
//       margin-left: 5px;
//       color: ${props => props.theme.colors.golden};
//       cursor: pointer;
//       position: relative;
//     }
//   }

//   @media screen and (max-width: 700px) {
//     padding: 10px 5px 5px;

//     > div {
//       justify-content: space-evenly;
//     }
//   }
// `

// export const BtnContato = styled.button<BtnContatoProps>`
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   border-radius: 4px;
//   border: 2px solid ${props => props.isActive? props.theme.colors.text : '#DEE3E1'};
//   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);
//   background-color: #FFF ;

//   padding: 8px 60px;

//   font-size: 16px;
//   color: ${props => props.isActive? props.theme.colors.text : '#8E9191'};

//   &:disabled {
//     cursor: not-allowed;
//     background-color: #bababa;
//     color: ${({ theme }) => theme.colors.text};
//     border: 1px solid #EAEAEA;
//   }

//   &:focus {
//     text-decoration: underline;
//   }
// `

export const RadioButton = styled.div`
  background: initial;
  border-radius: 4px;
  padding: 16px 12px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  position:relative;
  border: 1px solid #D2D2D2;
  color: ${props => props.theme.colors.placeholder};
  margin-bottom: 12px;
  transition: .2s;

  > label {
    transform: translateY(-12px);
    font-size: 12px;
  }

  > div {
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      flex-direction:row;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      margin-right: 10px;
    }

    input {
    color: ${ props => props.theme.colors.text};
    flex: 0;
    text-align:  right;
    border: 0;
    background: transparent;
    margin-right: 5px;

    &::placeholder {
      color: ${ props => props.theme.colors.placeholder};
    }

    & + span{
      margin-right: 0px;
      margin-left: 4px;
    }
  }

    & + span{
      margin-right: 0px;
      margin-left: 4px;
    }
  }
  svg {
    margin-right: 12px;
  }
`

interface BtnContatoProps {
  isActive: boolean;
}
export const BtnContato = styled.button<BtnContatoProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background-color: ${props => props.isActive? '#3DD599' : 'transparent'} ;
  border: solid 1px #3DD599;
  color: ${props => props.isActive? '#fff' : '#000'} ;
  border-radius: 4px;
  &:focus{
    text-decoration: underline;
  }
`
export const Line = styled.div`
  width: 65%;
  height: 1px;
  margin: 15px auto;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`
