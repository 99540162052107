/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useCallback, useMemo } from 'react'

import { FiCheck } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import ColoredBox from '../../components/ColoredBox'

import {
  Container,
  Content,
  GraphWrapper,
  ReceiveTypes,
  BoxButtons,
  ColoredBoxInfo,
  ReceiveBox,
  ButtonSelectBox,
  ButtonSimulationCalc,
  BtnVoltar,
} from './styles'
import usePersistedState from '../../hooks/usePersistedState'
import Header from '../../components/Header'
import { formatValue } from '../../utils/formatValues'
import LineGraph from '../../components/LineGraph'
import Button from '../../components/Button'

import Input from '../../components/Input'

import simulationPercentageInfo from '../../assets/simulation-percentage-info.svg'
import simulationYearsInfo from '../../assets/simulation-years-info.svg'
import simulationSelectPercentIcon from '../../assets/simulation-select-percent-icon.svg'
import simulationSelectTimeIcon from '../../assets/simulation-select-time-icon.svg'

import { UserData, UserDetails } from '../../utils/interfaces'

interface Values {
  year: number
  invested: number
  finalBalance: number
  rentability: number
}

const Simulation: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const history = useHistory()

  const [displayPercent, setDisplayPercent] = useState(false)
  const [displayTime, setDisplayTime] = useState(false)
  const [selectedReceive, setSelectedReceive] = usePersistedState<
    'PD' | 'PS' | null
  >('receiveTypeSelected', null)

  const [values, setValues] = useState<Values[]>([])
  const [totalInvested, setTotalInvested] = useState(0)
  const [totalBalance, setTotalBalance] = useState(0)
  const [launched, setLaunched] = useState(false)
  const [totalRentability, setTotalRentability] = usePersistedState(
    'totalRentability',
    0,
  )

  useEffect(() => setTotalRentability((totalBalance * 100) / totalInvested), [
    totalInvested,
    totalBalance,
    setTotalRentability,
  ])

  const [timeValueYears, setTimeValueYears] = usePersistedState<number>(
    'TimeValueYears',
    15,
  )
  const [percentualValuePercent, setPercentualValuePercent] = usePersistedState<
    number
  >('PercentualValuePercent', 0.1)

  const percentualValue = useMemo(
    () => formatValue(totalBalance * (percentualValuePercent / 100)),
    [percentualValuePercent, totalBalance],
  )
  const timeValue = useMemo(
    () => formatValue(totalBalance / (timeValueYears * 12)),
    [timeValueYears, totalBalance],
  )

  const createValues = useCallback(() => {
    const { contribuicaoBasica, years } = userDetails
    const actualYear = new Date().getFullYear()
    const lastYear = actualYear + parseInt(years.toString(), 10)

    const valuesCalculated: Values[] = []

    const investedByYear = 12 * contribuicaoBasica

    let totalInvestedTemp = totalInvested

    let temporaryFinalBalance = 0

    // valor * 12 * anos * 0,045

    for (let i = actualYear; i < lastYear; i += 1) {
      const yearInvested = investedByYear /*+
        (contribution * (-80 + 100 * Math.random() + 100)) / 100 */
      const rentability = 4 // -10 + 28 * Math.random()

      totalInvestedTemp += yearInvested

      const finalBalance =
        ((temporaryFinalBalance + yearInvested) * (rentability + 100)) / 100

      temporaryFinalBalance = finalBalance

      const val: Values = {
        year: i,
        invested: totalInvestedTemp,
        rentability,
        finalBalance,
      }

      valuesCalculated.push(val)
    }

    setTotalInvested(totalInvestedTemp)
    setTotalBalance(temporaryFinalBalance)

    setValues(valuesCalculated)
    setLaunched(true)
  }, [totalInvested, userDetails])

  useEffect(() => {
    if (!launched) createValues()
  }, [createValues, launched])

  const toggleSelectedReceive = useCallback(
    (selectedReceiveNow: 'PD' | 'PS' | null) => {
      setSelectedReceive(selectedReceiveNow)
      if (selectedReceiveNow === 'PD') {
        setDisplayTime(true)
        setDisplayPercent(false)
      } else if (selectedReceiveNow === 'PS') {
        setDisplayTime(false)
        setDisplayPercent(true)
      } else {
        setDisplayPercent(false)
        setDisplayTime(false)
      }
    },
    [setSelectedReceive],
  )

  function adicPerct() {
    if (percentualValuePercent >= 1.5) {
      setPercentualValuePercent(1.5)
    } else {
      setPercentualValuePercent(percentualValuePercent + 0.05)
    }
  }

  function tiraPerct() {
    if (percentualValuePercent <= 0.1) {
      setPercentualValuePercent(0.1)
    } else {
      setPercentualValuePercent(percentualValuePercent - 0.05)
    }
  }

  function adicAno() {
    if (timeValueYears >= 30) {
      setTimeValueYears(30)
    } else {
      setTimeValueYears(timeValueYears + 1)
    }
  }

  function tiraAno() {
    if (timeValueYears <= 5) {
      setTimeValueYears(5)
    } else {
      setTimeValueYears(timeValueYears - 1)
    }
  }

  useEffect(() => {
    toggleSelectedReceive(selectedReceive)
  }, [selectedReceive, toggleSelectedReceive])

  const handleChangeValues = useCallback(() => {
    history.push('/personal-infos')
  }, [history])

  const handleConfirmValues = useCallback(() => {
    // history.push('/confirm-ownership')
    history.push('/recommendation')
  }, [history])

  return (
    <>
      <Header>
        {userData?.name.split(' ')[0]}, confira a simulação que preparamos para
        você:
      </Header>
      <Container>
        <Content>
          <header>
            <ColoredBox color="pink" gradientDirection="left">
              <span>Valor Investido</span>
              <h3>{formatValue(totalInvested)}</h3>
              <small>Seu investimento no período</small>
            </ColoredBox>

            <ColoredBox color="green" gradientDirection="left">
              <span>Seu saldo projetado</span>
              <h3>{formatValue(totalBalance)}</h3>
              <small>Seu investimento + rentabilidade</small>
            </ColoredBox>
          </header>
          <article>
            <p>Os dados não mentem.</p>
            <p>
              Quer ver? Arraste seu dedo no gráfico e descubra a evolução do seu
              patrimônio no decorrer dos próximos anos.
            </p>
          </article>
          <GraphWrapper>
            <LineGraph
              data={[
                {
                  id: 'Saldo',
                  data: values.map(value => ({
                    x: new Date(
                      `12-31-${value.year}`.replace(/-/g, '/'),
                    ).toISOString(),
                    y: value.finalBalance,
                  })),
                },
              ]}
            />
          </GraphWrapper>

          <ReceiveTypes>
            <p>Desejo receber o valor mensalmente via:</p>
            <ReceiveBox>
              <ButtonSelectBox
                type="button"
                onClick={() => toggleSelectedReceive('PS')}
                selected={selectedReceive === 'PS'}
              >
                <img src={simulationSelectPercentIcon} alt="Percentual" />
                <span>Percentual sobre o saldo</span>
              </ButtonSelectBox>
              <BoxButtons displayed={displayPercent}>
                <ButtonSimulationCalc type="button" onClick={() => tiraPerct()}>
                  -
                </ButtonSimulationCalc>
                <ButtonSimulationCalc type="button">
                  {parseFloat(percentualValuePercent.toFixed(2))}
                </ButtonSimulationCalc>
                <ButtonSimulationCalc type="button" onClick={() => adicPerct()}>
                  +
                </ButtonSimulationCalc>
              </BoxButtons>

              <ColoredBoxInfo
                size="large"
                color="purple"
                gradientDirection="right"
                displayed={displayPercent}
              >
                <div>
                  <p>{percentualValue}*</p>
                  <small>Você receberá por mês</small>
                </div>
                <small>
                  *Valor simulado referente a{' '}
                  {percentualValuePercent.toFixed(2)}% do seu saldo projetado,
                  com pagamentos mensais efetuados com base no percentual que
                  escolher no momento da aposentadoria.{' '}
                </small>
                <img src={simulationPercentageInfo} alt="Percentual" />
              </ColoredBoxInfo>
            </ReceiveBox>

            <ReceiveBox>
              <ButtonSelectBox
                type="button"
                onClick={() => toggleSelectedReceive('PD')}
                selected={selectedReceive === 'PD'}
              >
                <img src={simulationSelectTimeIcon} alt="Tempo" />
                <span>Prazo determinado</span>
              </ButtonSelectBox>

              <BoxButtons displayed={displayTime}>
                <ButtonSimulationCalc type="button" onClick={() => tiraAno()}>
                  -
                </ButtonSimulationCalc>
                <ButtonSimulationCalc type="button">
                  {timeValueYears}
                </ButtonSimulationCalc>
                <ButtonSimulationCalc type="button" onClick={() => adicAno()}>
                  +
                </ButtonSimulationCalc>
              </BoxButtons>
              <ColoredBoxInfo
                size="large"
                color="green"
                gradientDirection="right"
                displayed={displayTime}
              >
                <div>
                  <p>{timeValue}*</p>
                  <small>Você receberá por mês</small>
                </div>
                <small>
                  *Valor simulado referente a modalidade de prazo determinado
                  com duração de {timeValueYears} anos calculado com base no seu
                  saldo projetado.{' '}
                </small>
                <img src={simulationYearsInfo} alt="Tempo" />
              </ColoredBoxInfo>
            </ReceiveBox>
            <small>
              Selecione uma das opções acima para simular o valor que você
              receberá mensalmente ao se aposentar
            </small>
          </ReceiveTypes>

          <Button
            type="button"
            fontSize="normal"
            color="green"
            width="large"
            disabled={selectedReceive === null}
            onClick={handleConfirmValues}
          >
            <FiCheck size={28} />
            Pronto! É isso que eu quero
          </Button>

          <Button type="button" fontSize="small" onClick={handleChangeValues}>
            Ops! Quero alterar os valores e simular novamente
          </Button>

          <BtnVoltar type="button" onClick={() => history.goBack()}>
            &lt; Anterior
          </BtnVoltar>

        </Content>
      </Container>
    </>
  )
}

export default Simulation
