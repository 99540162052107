/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Switch from 'react-switch'
import { Base64 } from 'js-base64'
import { CircularProgress } from '@material-ui/core'
import { FiCheck, FiClock } from 'react-icons/fi'
import { Container, Content, LogoContent, BtnVoltar } from './styles'
import Logo from '../../components/Logo'
import api from '../../services/api'
import Button from '../../components/Button'
import usePersistedState from '../../hooks/usePersistedState'

import {
  UserData,
  UserDetails,
  ConfigData,
  Participant,
  ErroProps,
  LegalResponsibleData,
  FinancialResponsibleData,
  IndicationData,
} from '../../utils/interfaces'
import ModalBox from '../../components/Modal'

const Conclusion: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [configData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [participants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [erroProps, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [legalRespData] = usePersistedState<LegalResponsibleData>(
    'legalRespData',
    {} as LegalResponsibleData,
  )

  const [financialRespData] = usePersistedState<FinancialResponsibleData>(
    'financialRespData',
    {} as FinancialResponsibleData,
  )

  const [indicationData] = usePersistedState<IndicationData>(
    'indicationData',
    {} as IndicationData,
  )
  const [opcoes] = usePersistedState<string[]>('goalsSelected', [])
  const [selectedReceive] = usePersistedState<'PD' | 'PS' | null>(
    'receiveTypeSelected',
    null,
  )
  const [timeValueYears] = usePersistedState<number>('TimeValueYears', 15)
  const [percentualValuePercent] = usePersistedState<number>(
    'PercentualValuePercent',
    0.1,
  )
  const [aguarde, setAguarde] = useState(false)
  const [timer, setTimer] = useState(0)
  const [reSend, setReSend] = useState(true)

  const [acceptTerms, setAcceptTerms] = usePersistedState('acceptTerms', false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [warningText, setWarningText] = useState('')

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const history = useHistory()

  const handleConfirmaAdesao = useCallback(
    async () => {
      const parametros = Base64.encode(
        `{
          "token": "${configData.token}",
          "versao": "${configData.tipo}",
          "plano": "${configData.plano}",
          "nome": "${userData.name.toLowerCase()}",
          "cpf": "${userData.cpf.replaceAll('.', '').replace('-', '')}",
          "nascimento": "${userData.birthdate}",
          "email": "${userData.email}",
          "telefone": "55${userData.phone
          .replace('(', '')
          .replace(') ', '')
          .replace('-', '')}",
          "tempo_contribuicao": "${userDetails.years}",
          "valor": "${userDetails.contribuicaoBasica}",
          "valor_resgate": "${selectedReceive === 'PS'
          ? percentualValuePercent.toFixed(2).toString().replace('.', ',')
          : timeValueYears
        }",
          "forma_resgate": "${selectedReceive}",
          "cpfIndicacao": "${indicationData.cpfIndicacao === undefined
          ? ''
          : indicationData.cpfIndicacao
            .replace('.', '')
            .replace('.', '')
            .replace('-', '')
        }",
          "parentescoIndicacao": "${indicationData.grauParentesco === undefined
          ? ''
          : indicationData.grauParentesco
        }",
          "nomeIndicacao" : "${indicationData.nomeIndicacao.toLowerCase() === undefined
          ? ''
          : indicationData.nomeIndicacao.toLowerCase()
        }",
          "cpfRespFinanc": "${financialRespData.cpfResponsavel === undefined
          ? ''
          : financialRespData.cpfResponsavel
            .replace('.', '')
            .replace('.', '')
            .replace('-', '')
        }",
          "emailRespFinanc": "${financialRespData.emailResponsavel === undefined
          ? ''
          : financialRespData.emailResponsavel
        }",
          "nomeRespFinanc": "${financialRespData.nomeResponsavel === undefined
          ? ''
          : financialRespData.nomeResponsavel.toLowerCase()
        }",
          "telRespFinanc": "${financialRespData.telResponsavel === undefined ||
          financialRespData.telResponsavel === ''
          ? ''
          : `55${financialRespData.telResponsavel
            .replace('(', '')
            .replace(') ', '')
            .replace('-', '')}`
        }",
          "respLegal": [{
            "cpfRespLeg": "${legalRespData.cpfResponsavelLeg === undefined
          ? ''
          : legalRespData.cpfResponsavelLeg
            .replace('.', '')
            .replace('.', '')
            .replace('-', '')
        }",
            "emailRespLeg": "${legalRespData.emailResponsavelLeg === undefined
          ? ''
          : legalRespData.emailResponsavelLeg
        }",
            "nomeRespLeg": "${legalRespData.nomeResponsavelLeg === undefined
          ? ''
          : legalRespData.nomeResponsavelLeg.toLowerCase()
        }",
            "telRespLeg": "${legalRespData.telResponsavelLeg === undefined ||
          legalRespData.telResponsavelLeg === ''
          ? ''
          : `55${legalRespData.telResponsavelLeg
            .replace('(', '')
            .replace(') ', '')
            .replace('-', '')}`
        }"}
          ],

          "objetivos": [
            ${opcoes.map(opc => `{"id": "${opc}"}`)}
          ]}`,
      )

      const parametrosFinal = Base64.encode(parametros)

      await api
        .post(`wsAdesao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
        .then(() => {
          localStorage.removeItem('@MaisPrev:acceptTerms')
          localStorage.removeItem('@MaisPrev:erroProps')
          localStorage.removeItem('@MaisPrev:goalsSelected')
          localStorage.removeItem('@MaisPrev:userDetails')
          localStorage.removeItem('@MaisPrev:receiveTypeSelected')
          localStorage.removeItem('@MaisPrev:totalRentability')
          localStorage.removeItem('@MaisPrev:TimeValueYears')
          localStorage.removeItem('@MaisPrev:PercentualValuePercent')
          localStorage.removeItem('@MaisPrev:legalRespData')
          localStorage.removeItem('@MaisPrev:financialRespData')
          localStorage.removeItem('@MaisPrev:indicationData')
          localStorage.removeItem('@MaisPrev:respFinanceiro')
          localStorage.removeItem('@MaisPrev:respLegal')
          localStorage.removeItem('@MaisPrev:respLegalEqualFin')
          localStorage.removeItem('@MaisPrev:Indicado')
          localStorage.removeItem('@MaisPrev:participantsGroup')
          history.push('/end')
        })
        .catch(res => {
          if (res.message === 'Request failed with status code 401') {
            alert('Tempo de sessão expirado')
            history.push('/register')
          } else if (
            res.message === 'Request failed with status code 406'
          ) {
            setWarningText(
              'Adesão não autorizada. Verifique se você já possui cadastro no plano.',
            )
            setIsModalOpen(true)
            setReSend(false)
            setTimer(20)
            setAguarde(false)
          } else if (res.message === 'Network Error') {
            setErroProps({
              title: res.message,
              description: 'Falha na conexão como servidor',
            })
            history.push('/erro')
          } else if (
            res.message === 'Request failed with status code 500'
          ) {
            setErroProps({
              title: 'Erro interno no servidor',
              description: res.message,
            })
            history.push('/erro')
          } else if (
            res.message === 'Request failed with status code 408'
          ) {
            alert('Tempo de sessão expirado')
            history.push('/register')
          } else {
            setReSend(false)
            setTimer(20)
            setAguarde(false)
            setWarningText('Ops, algo deu errado. Tente novamente mais tarde.')
            setIsModalOpen(true)
          }
        })
    },
    [
      history,
      opcoes,
      percentualValuePercent,
      selectedReceive,
      timeValueYears,
      configData,
      userData,
      userDetails,
    ],
  )

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
    }
    const interval = setInterval(countTimer, 1000)
    return () => clearInterval(interval)
  }, [timer])

  return (
    <Container>
      <LogoContent>
        <div>
          <Logo />
        </div>
      </LogoContent>
      <Content>
        <strong>Confirmação de Adesão</strong>
        <p>
          Eu {userData.name}, inscrito no CPF <b>{userData.cpf}</b>, declaro que
          ao clicar em <b>Confirmar Adesão</b> estou confirmando minha adesão ao{' '}
          <b> Plano Mais Setorial </b> e que as informação prestadas são
          verídicas.
        </p>
      </Content>
      <div>
        <span> Aceito os <a href="https://maisprevidencia.com.br/lgpd/" target="_blank">termos de uso</a></span>
        <Switch
          onChange={toggleAcceptTerms}
          checked={acceptTerms}
          checkedIcon={false}
          uncheckedIcon={false}
          height={22}
          width={44}
          handleDiameter={18}
          offColor="#EB6A9F"
          offHandleColor="#fff"
          onHandleColor="#fff"
          onColor="#31D19E"
          disabled={aguarde}
        />
      </div>
      <footer>
        {/*
        <p>
          Clicar no botão abaixo, finaliza o processo de adesão eletrônica.
        </p> */}
        <Button
          type="button"
          color="green"
          width="large"
          disabled={!acceptTerms || !reSend}
          // onClick={handleNewParticipant}
          onClick={handleConfirmaAdesao}
        >
          {!aguarde ? (
            !reSend ? (
              <>
                <FiClock size={45} />
                <span>
                  Tente novamente em {timer < 10 ? `0${timer}` : timer} segundos
                </span>
              </>
            ) : (
              <>
                <FiCheck size={45} />
                <span>Confirmar adesão</span>
              </>
            )
          ) : (
            <>
              <CircularProgress color="inherit" />
              <span>Aguarde</span>
            </>
          )}
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </footer>
      <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
        <p>{warningText}</p>
      </ModalBox>
    </Container>
  )
}

export default Conclusion
