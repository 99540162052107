/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Base64 } from 'js-base64'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { FiUser, FiCheck, FiClock } from 'react-icons/fi'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { MdSecurity } from 'react-icons/md'
import CircularProgress from '@material-ui/core/CircularProgress'
import Switch from 'react-switch'
import { Container, Content, BtnVoltar } from './styles'
import Header from '../../components/Header'
import usePersistedState from '../../hooks/usePersistedState'
import Input from '../../components/Input'
import Button from '../../components/Button'
import validaCPF from '../../utils/validaCPF'
import api from '../../services/api'
import { ValorMascarar } from '../../utils/masks'

import {UserData, UserDetails, ConfigData, ErroProps} from '../../utils/interfaces'
import ModalBox from '../../components/Modal'

const ConfirmOwnership: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [configData] = usePersistedState<ConfigData>('configData', {} as ConfigData)
  const [opcoes] = usePersistedState<string[]>('goalsSelected', [])
  const [selectedReceive] = usePersistedState<'PD' | 'PS' | null>(
    'receiveTypeSelected',
    null,
  )
  const [timeValueYears] = usePersistedState<number>('TimeValueYears', 15)
  const [percentualValuePercent] = usePersistedState<number>(
    'PercentualValuePercent',
    0.1,
  )
  const [erroProps, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [
    authorizeTitularPayment,
    setAuthorizeTitularPayment,
  ] = usePersistedState('authorizeTitularPayment', false)

  const [aguarde, setAguarde] = useState(false)
  const [timer, setTimer] = useState(0)
  const [reSend, setReSend] = useState(true)
  const { pathname } = useLocation()
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [warningText, setWarningText] = useState('')

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const formRef = useRef<FormHandles>(null)

  const toggleAuthorizeTitularPayment = useCallback(() => {
    setAuthorizeTitularPayment(!authorizeTitularPayment)
  }, [authorizeTitularPayment, setAuthorizeTitularPayment])

  const handleSubmit = useCallback(
    async data => {
      if (validaCPF(data.cpf.replaceAll('.', '').replace('-', '')) === true) {
        try {
          await setUserData({ ...userData, name: data.name, cpf: data.cpf })
          await setUserDetails({
            ...userDetails,
            contribuicaoBasica: parseFloat(
              data.contribution.replaceAll('.', '').replace(',', '.'),
            ),
          })
        } catch (err) {
          console.log(err)
        }
        setAguarde(true)
        if (data.cpf === '') alert('O campo CPF é obrigatório.')
        else {
          const parametros = Base64.encode(
            `{
            "token": "${configData.token}",
            "versao": "${configData.tipo}",
            "plano": "${configData.plano}",
            "nome": "${userData.name.toLowerCase()}",
            "cpf": "${data.cpf.replaceAll('.', '').replace('-', '')}",
            "nascimento": "${userData.birthdate}",
            "email": "${userData.email}",
            "telefone": "55${userData.phone
              .replace('(', '')
              .replace(') ', '')
              .replace('-', '')}",
            "patrocinadora":"${userData.patrocinadora}",
            "tempo_contribuicao": "${userDetails.years}",
            "valor": "${parseFloat(
                data.contribution.replaceAll('.', '').replace(',', '.'),
                ).toString()
                .replace('.', ',')}",
            "valor_resgate": "${
              selectedReceive === 'PS'
                ? percentualValuePercent.toFixed(2).toString().replace('.', ',')
                : timeValueYears
            }",
            "forma_resgate": "${selectedReceive}",
            "objetivos": [
              ${opcoes.map(opc => `{"id": "${opc}"}`)}
            ]}`,
          )

          const parametrosFinal = Base64.encode(parametros)

          await api
            .post(`wsAdesao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
            .then(() => {
              localStorage.removeItem('@MaisPrev:userDetails')
              localStorage.removeItem('@MaisPrev:codCliente')
              localStorage.removeItem('@MaisPrev:acceptTerms')
              localStorage.removeItem('@MaisPrev:participantsGroup')
              localStorage.removeItem('@MaisPrev:token')
              localStorage.removeItem('@MaisPrev:tipoCont')
              localStorage.removeItem('@MaisPrev:totalRentability')
              history.push('/end')
            })
            .catch(res => {
              if (res.message === 'Request failed with status code 401') {
                alert('Tempo de sessão expirado')
                history.push('/register')
              } else if (
                res.message === 'Request failed with status code 406'
              ) {
                setWarningText(
                  'Adesão não autorizada. Verifique se você já possui cadastro no plano.',
                )
                setIsModalOpen(true)
                setReSend(false)
                setTimer(20)
                setAguarde(false)
              } else if (res.message === 'Network Error') {
                setErroProps({
                  title: res.message,
                  description: 'Falha na conexão como servidor',
                })
                history.push('/erro')
              } else if (
                res.message === 'Request failed with status code 500'
              ) {
                setErroProps({
                  title: 'Erro interno no servidor',
                  description: res.message,
                })
                history.push('/erro')
              } else if (
                res.message === 'Request failed with status code 408'
              ) {
                alert('Tempo de sessão expirado')
                history.push('/register')
              } else {
                setReSend(false)
                setTimer(20)
                setAguarde(false)
                setWarningText('Ops, algo deu errado. Tente novamente mais tarde.')
                setIsModalOpen(true)
              }
            })
        }
      } else {
        alert('CPF inválido')
      }
    },
    [
      history,
      opcoes,
      percentualValuePercent,
      selectedReceive,
      setUserData,
      setUserDetails,
      timeValueYears,
      configData,
      userData,
      userDetails,
    ],
  )

  const handleHelp = useCallback(() => {
    history.push('/help', { backTo: pathname })
  }, [history, pathname])

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
    }
    const interval = setInterval(countTimer, 1000)
    return () => clearInterval(interval)
  }, [timer])

  return (
    <>
      <Header color="green" gradientDirection="left">
        Parabéns! <br />
        Agora, só confirme abaixo os dados de quem será o titular do plano.
      </Header>
      <Container>
        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              name: userData.name,
              cpf: userData.cpf,
              // bestDayPayment: userDetails.bestDayPayment,
              contribution: ValorMascarar(
                userDetails.contribuicaoBasica.toFixed(2).toString(),
              ),
              // paymentType: userDetails.paymentType,
            }}
          >
            <Input
              placeholder="Nome completo do TITULAR"
              name="name"
              icon={FiUser}
            />
            <Input
              placeholder="CPF do TITULAR"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
            />
            <div>
              <Input
                type="text"
                prefix="R$"
                mask="currency"
                step={0.01}
                name="contribution"
                icon={FaRegMoneyBillAlt}
                placeholder="Contribuição mensal"
              />
              {/* <InputSelect
                name="paymentType"
                options={[
                  { label: 'Boleto', value: 'boleto' },
                  { label: 'Cartão de crédito', value: 'credit' },
                  { label: 'Débito em conta', value: 'debito' },
                ]}
                placeholder="Forma de pagamento"
                icon={BiDollar}
              /> */}
            </div>
            {/* <Input
              placeholder="Melhor dia para pagamento"
              min={1}
              max={30}
              name="bestDayPayment"
              icon={FiCalendar}
              type="number"
              prefix="Dia"
            /> */}
          </Form>

          <div>
            <span>
              Autorizo a cobrança recorrente no valor informado acima.
            </span>
            <Switch
              onChange={toggleAuthorizeTitularPayment}
              checked={authorizeTitularPayment}
              checkedIcon={false}
              uncheckedIcon={false}
              height={22}
              width={44}
              handleDiameter={18}
              offColor="#EB6A9F"
              offHandleColor="#fff"
              onHandleColor="#fff"
              onColor="#31D19E"
            />
          </div>
        </Content>

        <Button
          type="button"
          fontSize="normal"
          color="green"
          width="large"
          disabled={!authorizeTitularPayment || !reSend}
          onClick={() => formRef.current?.submitForm()}
        >
          {!aguarde ? (
            !reSend ? (
              <>
                <FiClock size={45} />
                <span>
                  Tente novamente em {timer < 10 ? `0${timer}` : timer} segundos
                </span>
              </>
            ) : (
              <>
                <FiCheck size={45} />
                <span>Confirmo a aquisição do Plano Previdência Família</span>
              </>
            )
          ) : (
            <>
              <CircularProgress color="inherit" />
              <span>Aguarde</span>
            </>
          )}
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>

        {/* <Button type="button" fontSize="small" onClick={handleHelp}>
          Ops! Estou com dúvidas, preciso de ajudar para concluir
        </Button> */}
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <p>{warningText}</p>
        </ModalBox>
      </Container>
    </>
  )
}

export default ConfirmOwnership
