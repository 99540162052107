/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Collapse } from '@material-ui/core'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import {
  FiUser,
  FiSmartphone,
  FiMail,
  FiArrowLeft,
  FiArrowRight,
  FiAlertCircle,
} from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'
import ModalBox from '../../components/Modal'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import InputHidden from '../../components/InputHidden'
import InputSelect from '../../components/InputSelect'
// import InfoBox from '../../components/InfoBox'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Input from '../../components/Input'
import { Container, Content, RadioButton, BtnContato, Line } from './styles'
import {
  LegalResponsibleData,
  FinancialResponsibleData,
  IndicationData,
  UserDetails,
  UserData,
  ConfigData,
  ErroProps,
} from '../../utils/interfaces'
import validaCPF from '../../utils/validaCPF'
import { Base64 } from 'js-base64'
import api from '../../services/api'

const Recommendation: React.FC = () => {
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [userData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [configData] = usePersistedState<ConfigData>('configData', {} as ConfigData)

  const [erroProps, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [legalRespData, setLegalRespData] = usePersistedState<
    LegalResponsibleData
  >('legalRespData', {} as LegalResponsibleData)

  const [financialRespData, setFinancialRespData] = usePersistedState<
    FinancialResponsibleData
  >('financialRespData', {} as FinancialResponsibleData)

  const [indicationData, setIndicationData] = usePersistedState<IndicationData>(
    'indicationData',
    {} as IndicationData,
  )
  const [respFinanceiro, setRespFinanceiro] = usePersistedState(
    'respFinanceiro',
    userDetails.age < 18 ? 'S' : 'N',
  )
  const [respLegal, setRespLegal] = usePersistedState(
    'respLegal',
    userDetails.age < 18 ? 'S' : 'N',
  )
  const [respLegalEqualFin, setRespLegalEqualFin] = usePersistedState(
    'respLegalEqualFin',
    'N',
  )

  const [pessoaIndicacao, setpessoaIndicacao] = usePersistedState(
    'Indicado',
    'N',
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [titleModal, setTitleModal] = useState('')
  const [txtModal, setTxtModal] = useState('')

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const toggleRespLegalEqualFin = useCallback(
    isEqual => {
      setRespLegalEqualFin(isEqual)
      if (isEqual === 'S') {
        setFinancialRespData({
          ...financialRespData,
          nomeResponsavel: legalRespData.nomeResponsavelLeg,
          cpfResponsavel: legalRespData.cpfResponsavelLeg,
          telResponsavel: legalRespData.telResponsavelLeg,
          emailResponsavel: legalRespData.emailResponsavelLeg,
        })
      } else {
        setFinancialRespData({
          ...financialRespData,
          nomeResponsavel: '',
          cpfResponsavel: '',
          telResponsavel: '',
          emailResponsavel: '',
        })
      }
    },
    [
      financialRespData,
      legalRespData,
      setFinancialRespData,
      setRespLegalEqualFin,
    ],
  )

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          cpfIndicacao: Yup.string()
            .required('CPF de quem te indicou obrigatório')
            .test(
              '',
              'CPF inválido',
              () =>
                validaCPF(
                  data.cpfIndicacao.replaceAll('.', '').replace('-', ''),
                ) || data.cpfIndicacao === '',
            ),
          nomeIndicacao: Yup.string()
            .required('Nome de quem te indicou obrigatório')
            .matches(/\s/g, 'Digite o nome completo')
            .min(5, 'Digite o nome completo'),

          cpfResponsavel: Yup.string().when('responsavelFinanceiro', {
            is: 'S',
            then: Yup.string()
              .required('CPF é obrigatório')
              .test(
                '',
                'CPF inválido',
                () =>
                  validaCPF(
                    data.cpfResponsavel.replaceAll('.', '').replace('-', ''),
                  ) || data.cpfResponsavel === '',
              )
              .test(
                '',
                'CPF não pode ser igual ao do titular',
                () =>
                  data.cpfResponsavel !== userData.cpf ||
                  data.cpfResponsavel === ''
              ),
          }),
          nomeResponsavel: Yup.string().when('responsavelFinanceiro', {
            is: 'S',
            then: Yup.string().required('Nome do responsável é obrigatório'),
          }),
          emailResponsavel: Yup.string().when('responsavelFinanceiro', {
            is: 'S',
            then: Yup.string()
              .required('E-mail é obrigatório')
              .email('Digite um e-mail válido'),
          }),
          telResponsavel: Yup.string().when('responsavelFinanceiro', {
            is: 'S',
            then: Yup.string()
              .required('Telefone é obrigatório')
              .max(15, 'Telefone inválido'),
          }),
          nomeResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .required('Nome do responsável legal é obrigatório')
              .matches(/\s/g, 'Digite o nome completo'),
          }),
          cpfResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .required('CPF obrigatório')
              .test(
                '',
                'CPF inválido',
                () =>
                  validaCPF(
                    data.cpfResponsavelLeg.replaceAll('.', '').replace('-', ''),
                  ) || data.cpfResponsavelLeg === '',
              )
              .test(
                '',
                'CPF não pode ser igual ao do titular',
                () =>
                  data.cpfResponsavelLeg !== userData.cpf ||
                  data.cpfResponsavelLeg === ''
              ),
          }),
          telResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .max(15, 'Telefone inválido')
              .required('Celular obrigatório'),
          }),
          emailResponsavelLeg: Yup.string().when('responsavelLeg', {
            is: 'S',
            then: Yup.string()
              .required('E-mail é obrigatório')
              .email('Digite um e-mail válido'),
          }),
        })

        await schema.validate(data, { abortEarly: false })
        const parametros = Base64.encode(
          `{"versao":"1",
            "cliente":"${configData.codCliente}",
            "cpf":"${data.cpfIndicacao.replaceAll('.', '').replaceAll('-', '')}"
          }`,
        )

        const parametrosFinal = Base64.encode(parametros)

        await api
          .get(`wsParticipante.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(() => {
            if (respLegal === 'S') {
              setLegalRespData({
                ...legalRespData,
                cpfResponsavelLeg: data.cpfResponsavelLeg,
                nomeResponsavelLeg: data.nomeResponsavelLeg,
                emailResponsavelLeg: data.emailResponsavelLeg,
                telResponsavelLeg: data.telResponsavelLeg,
              })
            } else {
              setLegalRespData({
                ...legalRespData,
                cpfResponsavelLeg: '',
                nomeResponsavelLeg: '',
                emailResponsavelLeg: '',
                telResponsavelLeg: '',
              })
            }
            if (respFinanceiro === 'S') {
              setFinancialRespData({
                ...financialRespData,
                cpfResponsavel: data.cpfResponsavel,
                nomeResponsavel: data.nomeResponsavel,
                emailResponsavel: data.emailResponsavel,
                telResponsavel: data.telResponsavel,
              })
            } else {
              setFinancialRespData({
                ...financialRespData,
                cpfResponsavel: '',
                nomeResponsavel: '',
                emailResponsavel: '',
                telResponsavel: '',
              })
            }

            setIndicationData({
              ...indicationData,
              cpfIndicacao: data.cpfIndicacao,
              nomeIndicacao: data.nomeIndicacao,
              grauParentesco: data.grauParentesco,
            })

            history.push('/conclusion')
          })
          .catch(res => {
            if (res.message === 'Request failed with status code 404') {
              // alert('O CPF da pessoa que te indicou não consta em nossa base de dados')
              setTxtModal('O CPF da pessoa que te indicou não consta em nossa base de dados')
              setIsModalOpen(true)
            } else if (res.message === 'Network Error') {
              setErroProps({
                title: res.message,
                description: 'Falha na conexão como servidor',
              })
              history.push('/erro')
            } else if (
              res.message === 'Request failed with status code 500'
            ) {
              setErroProps({
                title: 'Erro interno no servidor',
                description: res.message,
              })
              history.push('/erro')
            }
          })
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      financialRespData,
      history,
      indicationData,
      legalRespData,
      respFinanceiro,
      respLegal,
      setFinancialRespData,
      setIndicationData,
      setLegalRespData,
    ],
  )

  const handleChangeValues = useCallback(() => {
    history.push('/confirm-ownership')
  }, [history])

  const handleConfirmValues = useCallback(async () => {
    if (respLegalEqualFin === 'S') {
      await toggleRespLegalEqualFin('S')
    }
    formRef.current?.submitForm()
  }, [respLegalEqualFin, toggleRespLegalEqualFin])

  useEffect(() => {
    if (userDetails.age < 18) {
      setRespFinanceiro('S')
      setRespLegal('S')
    }
  }, [setRespFinanceiro, setRespLegal, userDetails.age])

  return (
    <>
      <Header>
        Agora, preencha as informações sobre os responsáveis pelo seu plano
      </Header>

      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            cpfResponsavelLeg: legalRespData.cpfResponsavelLeg,
            nomeResponsavelLeg: legalRespData.nomeResponsavelLeg,
            emailResponsavelLeg: legalRespData.emailResponsavelLeg,
            telResponsavelLeg: legalRespData.telResponsavelLeg,
            cpfResponsavel: financialRespData.cpfResponsavel,
            nomeResponsavel: financialRespData.nomeResponsavel,
            emailResponsavel: financialRespData.emailResponsavel,
            telResponsavel: financialRespData.telResponsavel,
            cpfIndicacao: indicationData.cpfIndicacao,
            nomeIndicacao: indicationData.nomeIndicacao,
            grauParentesco: indicationData.grauParentesco,
          }}
        >
          <Content>
            <Input
              placeholder="Nome de quem indicou ao plano"
              name="nomeIndicacao"
              icon={FiUser}
            />
            <Input
              placeholder="CPF de quem indicou ao plano"
              name="cpfIndicacao"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
            />
            <Line />
            <RadioButton>
              {userDetails.age >= 18 ? (
                <label>Deseja indicar um responsável legal?</label>
              ) : (
                <label>Responsável legal?</label>
              )}
              <div>
                <div>
                  <BtnContato
                    type="button"
                    isActive={respLegal === 'S'}
                    onClick={() => setRespLegal('S')}
                  >
                    Sim
                  </BtnContato>
                </div>
                <div>
                  <BtnContato
                    type="button"
                    isActive={respLegal === 'N'}
                    onClick={() => {
                      setRespLegal('N')
                      setRespLegalEqualFin('N')
                    }}
                    disabled={userDetails.age < 18}
                  >
                    Não
                  </BtnContato>
                </div>
              </div>
            </RadioButton>
            <Collapse in={respLegal === 'S'} style={{ width: '100%' }}>
              <InputHidden
                type="hidden"
                name="responsavelLeg"
                value={respLegal}
              />
              <Input
                placeholder="CPF do responsável legal"
                name="cpfResponsavelLeg"
                icon={MdSecurity}
                mask="cpf"
                maxLength={14}
                onChange={e => {
                  setLegalRespData({
                    ...legalRespData,
                    cpfResponsavelLeg: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="Nome do responsável legal"
                name="nomeResponsavelLeg"
                icon={FiUser}
                onChange={e => {
                  setLegalRespData({
                    ...legalRespData,
                    nomeResponsavelLeg: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="E-mail do responsável legal"
                name="emailResponsavelLeg"
                type="mail"
                icon={FiMail}
                value={legalRespData.emailResponsavelLeg}
                onChange={e => {
                  setLegalRespData({
                    ...legalRespData,
                    emailResponsavelLeg: e.target.value.replace(' ', ''),
                  })
                }}
              />
              <Input
                placeholder="Telefone do responsável legal"
                name="telResponsavelLeg"
                type="tel"
                mask="phone"
                icon={FiSmartphone}
                onChange={e => {
                  setLegalRespData({
                    ...legalRespData,
                    telResponsavelLeg: e.target.value,
                  })
                }}
              />
            </Collapse>

            <Line />

            {userDetails.age >= 18 && (
              <RadioButton>
                {userDetails.age >= 18 ? (
                  <label>Deseja indicar um responsável financeiro?</label>
                ) : (
                  <label>Responsável financeiro?</label>
                )}
                <div>
                  <div>
                    <BtnContato
                      type="button"
                      isActive={respFinanceiro === 'S'}
                      onClick={() => setRespFinanceiro('S')}
                    >
                      Sim
                    </BtnContato>
                  </div>
                  <div>
                    <BtnContato
                      type="button"
                      isActive={respFinanceiro === 'N'}
                      onClick={() => {
                        setRespFinanceiro('N')
                        setRespLegalEqualFin('N')
                      }}
                      disabled={userDetails.age < 18}
                    >
                      Não
                    </BtnContato>
                  </div>
                </div>
              </RadioButton>
            )}
            <Collapse
              in={respLegal === 'S' && respFinanceiro === 'S'}
              style={{ width: '100%' }}
            >
              <RadioButton>
                <label>
                  O responsável legal e o responsável financeiro são a mesma
                  pessoa?
                </label>
                <div>
                  <div>
                    <BtnContato
                      type="button"
                      isActive={respLegalEqualFin === 'S'}
                      onClick={() => toggleRespLegalEqualFin('S')}
                    >
                      Sim
                    </BtnContato>
                  </div>
                  <div>
                    <BtnContato
                      type="button"
                      isActive={respLegalEqualFin === 'N'}
                      onClick={() => toggleRespLegalEqualFin('N')}
                    >
                      Não
                    </BtnContato>
                  </div>
                </div>
              </RadioButton>
            </Collapse>

            <Collapse
              in={
                (respFinanceiro === 'S' &&
                  respLegal === 'S' &&
                  respLegalEqualFin === 'N') ||
                (respFinanceiro === 'S' && respLegal === 'N')
              }
              style={{ width: '100%' }}
            >
              <InputHidden
                type="hidden"
                name="responsavelFinanceiro"
                value={respFinanceiro}
              />
              <Input
                placeholder="CPF do responsável financeiro"
                name="cpfResponsavel"
                icon={MdSecurity}
                mask="cpf"
                maxLength={14}
                onChange={e => {
                  setFinancialRespData({
                    ...financialRespData,
                    cpfResponsavel: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="Nome do responsável financeiro"
                name="nomeResponsavel"
                icon={FiUser}
                onChange={e => {
                  setFinancialRespData({
                    ...financialRespData,
                    nomeResponsavel: e.target.value,
                  })
                }}
              />
              <Input
                placeholder="E-mail do responsável financeiro"
                name="emailResponsavel"
                type="mail"
                value={financialRespData.emailResponsavel}
                icon={FiMail}
                onChange={e => {
                  setFinancialRespData({
                    ...financialRespData,
                    emailResponsavel: e.target.value.replace(' ', ''),
                  })
                }}
              />
              <Input
                placeholder="Telefone do responsável"
                name="telResponsavel"
                type="tel"
                mask="phone"
                icon={FiSmartphone}
                onChange={e => {
                  setFinancialRespData({
                    ...financialRespData,
                    telResponsavel: e.target.value,
                  })
                }}
              />
              {/* <InputHidden name="age" type="hidden" value={ageResponsavel}/> */}
            </Collapse>
          </Content>
        </Form>

        <Button type="button" color="green" onClick={handleConfirmValues}>
          Avançar
          <div>
            <FiArrowRight />
          </div>
        </Button>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <strong>{titleModal}</strong>
          <div>
            <p>{txtModal}</p>
          </div>
        </ModalBox>
      </Container>
    </>
  )
}

export default Recommendation
