import React, { HTMLAttributes } from 'react'

import { Container } from './styles'

interface Props extends HTMLAttributes<HTMLDivElement> {
  color?: 'white' | 'green' | 'pink' | 'purple'
  gradientDirection?: 'left' | 'right'
}
const Header: React.FC<Props> = ({
  color = 'white',
  className,
  gradientDirection = 'left',
  children,
}) => {
  return (
    <Container
      className={className}
      color={color}
      gradientDirection={gradientDirection}
    >
      <strong>{children}</strong>
    </Container>
  )
}

export default Header
