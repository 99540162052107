/* eslint-disable react/jsx-wrap-multilines */
import React, { useRef, useCallback, useState, useEffect } from 'react'

import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'

import { Base64 } from 'js-base64'
// import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import InputSMSCode from '../../components/InputSMSCode'
import usePersistedState from '../../hooks/usePersistedState'
import api from '../../services/api'

import { Container, Content, Timer, LinkBack, ContentBtn } from './styles'
import Button from '../../components/Button'
import Header from '../../components/Header'

import { UserData, ConfigData, ErroProps } from '../../utils/interfaces'

const ConfirmSMS: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [erroProps, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [sizeCode] = useState(4)
  const [timer, setTimer] = useState(60)
  const [reSend, setReSend] = useState(false)
  const [timerSeguir, setTimerSeguir] = useState(60)
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const contato = configData.tipoContato === 'S' ? 'SMS' : 'e-mail'

  const handleSubmit = useCallback(
    async data => {
      // console.log(codCliente)
      if (data.codeSMS.length === sizeCode) {
        const parametros = Base64.encode(
          `{"token": "${configData.token}",
          "codigoValidador": "${data.codeSMS}",
          "cliente": ${configData.codCliente}}`,
        ) // 1003

        const parametrosFinal = Base64.encode(parametros)

        await api
          .post(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(() => history.push('/goal-selection'))
          .catch(res => {
            if (res.message === 'Request failed with status code 401') {
              // alert('Código inválido, tente novamente.')
              setOpen(true)
            } else {
              alert('Ops, algo deu errado. Tente novamente mais tarde.')
            }
          })
      }
    },
    [history, sizeCode, configData],
  )

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
      if (timerSeguir > 1) {
        setTimerSeguir(timerSeguir - 1)
      } else if (timerSeguir === 1) {
        setTimerSeguir(timerSeguir - 1)
      }
    }

    const interval = setInterval(countTimer, 1000)
    return () => clearInterval(interval)
  }, [timer, timerSeguir])

  const handleReSend = useCallback(() => {
    const parametrosGet = Base64.encode(
      `{"versao":"${configData.tipo}",
        "plano": "${configData.plano}",
        "cliente":"${configData.codCliente}",
        "cpf":"${userData.cpf === undefined ? '' : userData.cpf.replaceAll('.', '').replace('-', '')}",
        "email":"${userData.email === undefined ? '' : userData.email}",
        "telefone":"${
          userData.phone === undefined
            ? ''
            : `55${userData.phone
                .replace('(', '')
                .replace(') ', '')
                .replace('-', '')}`
        }",
        "admissao":"",
        "envio":"${configData.tipoContato}"}`,
    )

    const parametrosFinalGet = Base64.encode(parametrosGet)

    api
      .get(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinalGet}`)
      .then(res => setConfigData({ ...configData, token: res.data.token }))
      .catch(res => {
        if (res.message === 'Network Error') {
          setErroProps({
            title: res.message,
            description: 'Falha na conexão como servidor',
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 500') {
          setErroProps({
            title: 'Erro interno no servidor',
            description: res.message,
          })
          history.push('/erro')
        } else {
          alert('Ops, algo deu errado. Tente novamente mais tarde.')
        }
      })

    history.push('/register/confirm-sms')

    setReSend(false)
    setTimer(60)
  }, [configData, history, setConfigData, setErroProps, userData])

  return (
    <>
      <Header>
        Planeje sua aposentadoria de verdade ou invista para conquistar o que
        quer no futuro.
      </Header>
      <Container>
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  window.location.reload(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Código incorreto!
          </Alert>
          <br />
        </Collapse>
        <Content>
          <strong>
            {`${
              userData?.name.split(' ')[0]
            }, digite aqui o código de verificação
            que acabamos de te enviar via ${contato}.`}
          </strong>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputSMSCode name="codeSMS" size={sizeCode} formRef={formRef} />
          </Form>
          <p>
            Não recebeu o código em um minuto?
            <br />
            Clique em reenviar e pronto!
          </p>
          <Timer>00:{timer < 10 ? `0${timer}` : timer}</Timer>
          <Button
            onClick={handleReSend}
            type="button"
            color="green"
            disabled={!reSend}
          >
            Reenviar
          </Button>
          {/* <Button
            onClick={handleReSend}
            type="button"
            color="green"
            disabled={!reSend}
          >
            Prosseguir
          </Button> */}
        </Content>
        <LinkBack to="/register">Quero trocar a forma de contato</LinkBack>
      </Container>
    </>
  )
}

export default ConfirmSMS
