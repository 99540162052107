import React, { useCallback } from 'react'

import { useHistory } from 'react-router-dom'
import { Container, Content } from './styles'

import Logo from '../../components/Logo'

import endImage from '../../assets/end-image.svg'
import endFacebook from '../../assets/end-facebook.svg'
import endInstagram from '../../assets/end-instagram.svg'
import endYoutube from '../../assets/end-youtube.svg'

import Button from '../../components/Button'
import usePersistedState from '../../hooks/usePersistedState'

import { UserData } from '../../utils/interfaces'

const End: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )

  const history = useHistory()

  const handleNewParticipant = useCallback(() => {
    localStorage.removeItem('@MaisPrev:userData')
    history.push('/register')
  }, [history])

  return (
    <Container>
      <Logo />
      <Content>
        <strong>
          Pronto! Seu pedido de aquisição do Plano Mais Previdência Família foi
          salvo com sucesso!
        </strong>
        <img src={endImage} alt="Finalizar" />
        <p>
          Enviamos um e-mail para você com os próximos passos a serem seguidos
          para completar sua adesão.
        </p>
        <div>
          <small>
            Aguarde e enquanto isso, acompanhe os nossos perfis nas mídias
            sociais:{' '}
          </small>
          <div>
            <a
              href="https://www.facebook.com/maisprevidencia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={endFacebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/maisprevidencia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={endInstagram} alt="instagram" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCUHHbIAg_sQ22-uzOEoSbiw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={endYoutube} alt="youtube" />
            </a>
          </div>
        </div>
      </Content>
      <footer>
        <p>
          {userData.name.split(' ')[0]}, que tal realizar a adesão de mais algum
          familiar?
        </p>
        <Button
          type="button"
          color="green"
          width="large"
          onClick={handleNewParticipant}
        >
          Sim, eu quero!
        </Button>
      </footer>
    </Container>
  )
}

export default End
