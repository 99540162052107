import React, { useState, useCallback, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { Container, Content, Main, GoalItem, Arrow } from './styles'

import usePersistedState from '../../hooks/usePersistedState'
import Button from '../../components/Button'

import goalRetirement from '../../assets/goal-retirement.svg'
import goalTravel from '../../assets/goal-travel.svg'
import goalHouse from '../../assets/goal-house.svg'
import goalSuccessors from '../../assets/goal-successors.svg'
import goalOther from '../../assets/goal-other.svg'
import goalChildrenEducation from '../../assets/goal-children-education.svg'
import Header from '../../components/Header'

const GoalSelection: React.FC = () => {
  const goalItems = [
    {
      name: 'Comprar automóvel',
      id: 'CA',
      icon: goalRetirement,
    },
    {
      name: 'Viajar',
      id: 'VJ',
      icon: goalTravel,
    },
    {
      name: 'Educar os filhos',
      id: 'ED',
      icon: goalChildrenEducation,
    },
    {
      name: 'Comprar imóveis',
      id: 'IM',
      icon: goalHouse,
    },
    {
      name: 'Deixar bens para herdeiros',
      id: 'HE',
      icon: goalSuccessors,
    },
    {
      name: 'Outros',
      id: 'OU',
      icon: goalOther,
    },
  ]

  const [goalsSelected, setGoalsSelected] = usePersistedState<string[]>(
    'goalsSelected',
    [],
  )

  const history = useHistory()

  const toggleSelected = useCallback(
    (index, v) => {
      if (goalsSelected.filter(id => id === v).length) {
        setGoalsSelected(() => goalsSelected.filter(id => id !== v))
      } else {
        setGoalsSelected([...goalsSelected, v])
      }
    },
    [goalsSelected, setGoalsSelected],
  )

  const handleContinue = useCallback(() => {
    history.push('/personal-infos')
  }, [history])

  return (
    <>
      <Header>
        Quais são os seus principais objetivos ao investir em previdência
        privada?
      </Header>
      <Container>
        <Content>
          <p>Selecione uma ou mais opções</p>
          <Main>
            {goalItems.map((value, index) => (
              <GoalItem
                key={value.name}
                isSelected={
                  !!(
                    goalsSelected.length &&
                    goalsSelected.filter(id => {
                      return id === value.id
                    }).length
                  )
                }
                onClick={() => toggleSelected(index, value.id)}
              >
                <div>
                  <img src={value.icon} alt={value.name} />
                  <span>{value.name}</span>
                </div>
              </GoalItem>
            ))}
          </Main>
          <footer>
            <p>
              O Plano Mais Previdência Família é uma opção inteligente e segura
              de você juntar dinheiro para realizar seus sonhos de longo prazo.{' '}
            </p>
            <p>
              Por isso, o ideal é que pense nos seus planos para daqui, no
              mínimo, dez anos.
            </p>
          </footer>
          <Arrow />
        </Content>
        <Button
          type="button"
          color="green"
          onClick={handleContinue}
          disabled={!goalsSelected.length}
        >
          Continuar
        </Button>
      </Container>
    </>
  )
}

export default GoalSelection
