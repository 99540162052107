/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useRef, useState } from 'react'
import { FiUser, FiSmartphone, FiMail } from 'react-icons/fi'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { Base64 } from 'js-base64'
import api from '../../services/api'
import getValidationErrors from '../../utils/getValidationErrors'
import Input from '../../components/Input'
import InputHidden from '../../components/InputHidden'
import Button from '../../components/Button'
import usePersistedState from '../../hooks/usePersistedState'
import { Container, RadioButton, BtnContato } from './styles'
import Header from '../../components/Header'

import { UserData, ConfigData } from '../../utils/interfaces'
import ModalBox from '../../components/Modal'

const Register: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [tipoContato, setTipoContato] = useState(configData.tipoContato ? configData.tipoContato : 'S')
  const [tipoPessoa] = useState('F')
  const [cpfFuncionario] = useState('')
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const [tel, setTel] = useState(userData.phone === undefined ? '' : userData.phone)
  const [mail, setMail] = useState(userData.email === undefined ? '' : userData.email)
  const [clicado, setClicado] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [warningText, setWarningText] = useState('')

  function handleCloseModal(): void {
    setIsModalOpen(false)
  }

  const handleSubmit = useCallback(
    async (data: UserData) => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(3, 'Digite o nome completo'),
          tipContato: Yup.string().required(),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .min(14, 'Telefone inválido')
            .required('Celular obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        const parametros = Base64.encode(
          `{"versao":"${configData.tipo}",
          "plano":"${configData.plano}",
          "email":"${data.email ? data.email : ''}",
          "telefone":"${
            data.phone
            ? `55${data.phone
              .replace('(', '')
              .replace(') ', '')
              .replace('-', '')}`
            : ''
          }",
          "cpf":"",
          "admissao":"",
          "cliente":"${configData.codCliente}",
          "envio":"${tipoContato}"}`,
        )


        // const parametros = Base64.encode(
        //   `{"versao":"1",
        //   "email":"${data.email === undefined ? '' : data.email}",
        //   "telefone":"${
        //     data.phone === undefined
        //       ? ''
        //       : `55${data.phone
        //           .replace('(', '')
        //           .replace(') ', '')
        //           .replace('-', '')}`
        //   }",
        //   "cpf":"",
        //   "admissão":"",
        //   "cliente":"${configData.codCliente}","sistema":"ADZ","envio":"${tipoContato}"}`,
        // )

        const parametrosFinal = Base64.encode(parametros)

        await api
          .get(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(res => {
            setConfigData({
              ...configData,
              token: res.data.token,
              tipoContato
            })
            setUserData({
              ...userData,
              name: data.name,
              phone: data.phone,
              email: data.email,
            })
            // history.push('/personal-infos')
            history.push('/register/confirm-sms')
          })
          .catch(() => {
            setWarningText(
              'Ops, algo deu errado. Tente novamente com outra forma de contato.',
            )
            setIsModalOpen(true)
            setClicado(false)
          })
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [history, setConfigData, configData, setUserData, tipoContato],
  )

  const handleConfirmar = useCallback(event => {
    event.preventDefault()
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header>
        Planeje sua aposentadoria de verdade ou invista para conquistar o que
        quer no futuro.
      </Header>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={userData}>
          <Input name="name" placeholder="Nome Completo" icon={FiUser} />


          <Input
            name="phone"
            mask="phone"
            prefix="+55 | "
            placeholder="Celular com DDD"
            icon={FiSmartphone}
            value={tel}
            onChange={e => setTel(e.target.value)}
          />

          <Input
            icon={FiMail}
            name="email"
            id="email"
            type="email"
            placeholder="E-mail"
            maxLength={150}
            value={mail}
            onChange={e => setMail(e.target.value)}
          />

          <RadioButton>
            <label>Enviar código por: </label>
            <div>
              <div>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'S'}
                  onClick={() => setTipoContato('S')}
                >
                  SMS
                </BtnContato>
              </div>
              <div>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'E'}
                  onClick={() => setTipoContato('E')}
                >
                  E-mail
                </BtnContato>
              </div>
            </div>
          </RadioButton>
          <InputHidden name="tipContato" value={tipoContato} type="hidden" />
          <Button
            type="submit"
            color="green"
            onClick={handleConfirmar}
            disabled={clicado}
          >
            Confirmar
          </Button>
        </Form>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <p>{warningText}</p>
        </ModalBox>
      </Container>
    </>
  )
}

export default Register
