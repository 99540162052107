import React from 'react'

import { Container } from './styles'

import logoImage from '../../assets/logo-mais-prev.png'

const Logo: React.FC = () => {
  return <Container src={logoImage} alt="Mais Previdência" />
}

export default Logo
