import React, { useState, useCallback, useEffect } from 'react'
import Switch from 'react-switch'
import { useHistory } from 'react-router-dom'

import Button from '../../components/Button'
import Logo from '../../components/Logo'

import api from '../../services/api'

import { Container, DivContent, Content, Footer, BtnVoltar } from './styles'

import Dots from './components/Dots'

import welcomeWallet from '../../assets/welcome-wallet.svg'
import welcomeHandPork from '../../assets/welcome-hand-pork.svg'
import welcomeEnd from '../../assets/welcome-end.svg'
import usePersistedState from '../../hooks/usePersistedState'

const BemVindo: React.FC = () => {
  const [active, setActive] = useState(0)
  const [quantity, setQuantity] = useState(3)
  const [acceptTerms, setAcceptTerms] = usePersistedState('acceptTerms', false)

  const history = useHistory()

  const handleSetProximo = useCallback(() => {
    const newActive = active + 1
    if (newActive < quantity) {
      setActive(newActive)
    }
  }, [active, quantity])

  const handleSetAnterior = useCallback(() => {
    const newActive = active - 1
    if (newActive >= 0) {
      setActive(newActive)
    }
  }, [active])

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  const handleStart = useCallback(() => {
    if (acceptTerms) {
      history.push('/register')
    } else {
      console.warn('is necessary accept the terms to continue.')
    }
  }, [acceptTerms, history])

  return (
    <Container>
      <Logo />
      <DivContent>
        <Content isActive={active === 0}>
          <img src={welcomeWallet} alt="Carteira" />
          <strong>Quer ter uma aposentadoria de verdade?</strong>
          <span>
            Com o Plano Mais Previdência Família, você consegue e a partir de
            apenas R$ 79,00.
          </span>
          <div />
        </Content>

        <Content isActive={active === 1}>
          <img src={welcomeHandPork} alt="Carteira" />
          <strong>
            Investir em previdência privada te proporciona dedução de até 12% da
            base de cálculo do seu Imposto de Renda anual.
          </strong>
          <span />
          <div />
        </Content>

        <Content isActive={active === 2}>
          <img src={welcomeEnd} alt="Carteira" />
          <strong>Aqui tudo é simples e direto!</strong>
          <span>
            Surgiu algo inesperado? <br />
            Te damos a opção de fazer resgates parciais a partir do 3º ano.
          </span>
          <div>
            <span> Aceito os <a href="https://maisprevidencia.com.br/lgpd/" target="_blank">termos de privacidade</a></span>
            <Switch
              onChange={toggleAcceptTerms}
              checked={acceptTerms}
              checkedIcon={false}
              uncheckedIcon={false}
              height={22}
              width={44}
              handleDiameter={18}
              offColor="#EB6A9F"
              offHandleColor="#fff"
              onHandleColor="#fff"
              onColor="#31D19E"
            />
          </div>
        </Content>
      </DivContent>
      <Footer>
        <Dots quantity={quantity} active={active} />
        <Button
          color="green"
          onClick={handleSetProximo}
          isVisible={active + 1 !== quantity}
        >
          Próximo
        </Button>
        <Button
          color="green"
          onClick={handleStart}
          disabled={!acceptTerms}
          isVisible={active + 1 === quantity}
        >
          Começar
        </Button>
        {active > 0 && (
          <BtnVoltar type="button" onClick={handleSetAnterior}>
            &lt; Anterior
          </BtnVoltar>
        )}
      </Footer>
    </Container>
  )
}

export default BemVindo
