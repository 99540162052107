import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  margin:0 auto;
  align-items: center;
  height:100%;
  min-height:100vh;
  max-width: 420px;
  margin-top:-25px;
  padding-top:25px;

  @media screen and (min-width: 766px){
    max-width: 500px;
  }
`;
export const Content = styled.main`

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 15px 15px;

  > form {
    width: 100%;
  }

  > small {
    color: #8a8a8a;
    font-size:11px;
    text-align: center;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 766px){
    > small {
      font-size: 12px;
    }
  }
`

export const BoxValue = styled.div`
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 12px 14px rgba(209,212,226, 0.4);
  padding: 15px;
  background:#FFF;

  margin: 25px 0;

  > p {
    font-weight: bold;
    color: #8a8a8a;
    margin-bottom: 12px;
  }

  > span {
    color: #ca6048;
    font-size: 12px;
    font: 'Verdana';
  }

  > div:last-child {
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    > div {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-left: 10px;
      > p {
        color: #1c1c1c;
        font-size: 12px;
        margin:0;
      }
      > input {
        color: #31D19E;
        font-size: 18px;
        font-weight: bold;
        outline:0;
        border: 0px;
        text-align: center;
        width: 100%;
        height: 40px;
      }
      > small {
        color: #8a8a8a;
        font-size: 9px;
        width: 100%;
        text-align: center;
      }
    }
  }



  @media screen and (min-width: 766px){
    padding: 16px;
    margin: 30px 0px;

    > p {
      margin-bottom: 14px;
    }

    > div:last-child {
      margin-top: 14px;

      > div {
        width: auto;

        > p {
          color: #1c1c1c;
          font-size: 13px;
          margin:0;
        }
        > h3 {
          color: #31D19E;
          font-size: 19px;
          font-weight: bold;
        }
        > small {
          color: #8a8a8a;
          font-size: 10px;
        }
      }
    }

  }

`
export const BtnVoltar = styled.button`
  border: none;
  background-color: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid transparent;
  padding: 0px 3px 2px;
  color: ${({ theme }) => theme.colors.text};

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`
