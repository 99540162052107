import styled, { css } from 'styled-components';



interface ContainerProps {
  color?: 'green' | 'pink' | 'white'
  fontSize?: 'small' | 'normal' | 'large'
  width?: 'small' | 'medium' | 'large'
}

const buttonTypesVariation = {
  green: css`
    background-image: linear-gradient(60deg,#6DE381,#31D19E);
    color: ${({ theme }) => theme.colors.textSecondary};
    box-shadow: 0px 12px 16px rgba(54, 211, 156,0.6);
  `,
  pink: css`
    background-image: linear-gradient(60deg,#FBADB4,#EB6A9F);
    color: ${({ theme }) => theme.colors.textSecondary};
    box-shadow: 0px 12px 16px rgba(250, 168, 179,0.6);
  `,
  white: css`
    background-color: #FFFFFF;
    color: ${({ theme }) => theme.colors.text};
    box-shadow: 0px 12px 16px rgba(101, 101, 101,0.09);
    border: 1px solid #EAEAEA;
  `,
  disabled: css`
    background-color: #bababa;
    color: ${({ theme }) => theme.colors.textSecondary};
    box-shadow: 0px 12px 16px rgba(101, 101, 101,0.09);
    border: 1px solid #EAEAEA;
  `,
}


const fontSizeVariation = {
  small: css`
    min-height: 40px;
    font-size: 12px;
    font-weight: normal;
  `,
  normal: css`
    min-height: 50px;
    font-size: 16px;
    font-weight: bold;
  `,
  large: css`
    min-height: 50px;
    font-size: 18px;
    font-weight: bold;
  `,
}

const widthVariation = {
  small: css`
    width: 200px;
    padding: 10px 16px;
  `,
  medium: css`
    width: 250px;
    padding: 12px 18px;
  `,
  large: css`
    width: 300px;
    padding: 12px 18px;
  `,
}

export const Container = styled.button<ContainerProps>`
  border: none;
  border-radius: 30px;
  text-align: center;
  margin-bottom: 25px;

svg{
  margin-right:4px;
}

  ${({ fontSize }) => fontSizeVariation[fontSize || 'normal']}
  ${({ width }) => widthVariation[width || 'medium']}
  ${props => !props.disabled ? buttonTypesVariation[props.color || 'white'] : buttonTypesVariation['disabled']}
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  display: flex;
  align-items: center;
  justify-content: space-around;
  &:focus{
    text-decoration: underline;
  }
`;
